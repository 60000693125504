var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-container"},[_c('picture',{attrs:{"data-test":"banner-picture"}},[_c('img',_vm._b({directives:[{name:"lazyload",rawName:"v-lazyload"}],ref:"image",staticClass:"banners__img--mobile banners__img",class:[
        'banners__img',
        'lazy',
        _vm.$attrs.class,
        { loaded: !_vm.loadingImage } ],attrs:{"data-src":_vm.mobileSrc,"title":_vm.bannerName,"alt":_vm.bannerName,"data-test":"banner-image"},on:{"load":_vm.onLoad}},'img',_vm.$attrs,false)),_vm._v(" "),_c('img',_vm._b({directives:[{name:"lazyload",rawName:"v-lazyload"}],ref:"image",staticClass:"banners__img--desktop banners__img",class:[
        'banners__img',
        'lazy',
        _vm.$attrs.class,
        { loaded: !_vm.loadingImage } ],attrs:{"data-src":_vm.desktopSrc,"title":_vm.bannerName,"alt":_vm.bannerName,"data-test":"banner-image"},on:{"load":_vm.onLoad}},'img',_vm.$attrs,false))]),_vm._v(" "),(_vm.countdownUntil)?_c('CountdownTimer',{attrs:{"countdown-until":_vm.countdownUntil}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }