<template>
  <div
    v-if="isCountdownReadyToStart"
    class="countdown"
    role="timer"
    aria-live="polite"
  >
    <div class="countdown__box">
      <span
        :class="{ 'countdown__value--updated': validatedIsUpdated.Hours }"
        class="countdown__value"
        aria-label="Hours"
      >
        {{ validatedCountdownSegments.Hours }}
      </span>
      <span class="countdown__label">{{ $t('countdown.hours') }}</span>
    </div>
    <span class="countdown__separator" aria-hidden="true">:</span>
    <div class="countdown__box">
      <span
        :class="{ 'countdown__value--updated': validatedIsUpdated.Minutes }"
        class="countdown__value"
        aria-label="Minutes"
      >
        {{ validatedCountdownSegments.Minutes }}
      </span>
      <span class="countdown__label">{{ $t('countdown.minutes') }}</span>
    </div>
    <span class="countdown__separator" aria-hidden="true">:</span>
    <div class="countdown__box">
      <span
        :class="{ 'countdown__value--updated': validatedIsUpdated.Seconds }"
        class="countdown__value"
        aria-label="Seconds"
      >
        {{ validatedCountdownSegments.Seconds }}
      </span>
      <span class="countdown__label">{{ $t('countdown.seconds') }}</span>
    </div>
  </div>
</template>

<script>
const ONE_SECOND_IN_MILLISECONDS = 1000
const ONE_MINUTE_IN_MILLISECONDS = ONE_SECOND_IN_MILLISECONDS * 60
const ONE_HOUR_IN_MILLISECONDS = ONE_MINUTE_IN_MILLISECONDS * 60

export default {
  props: {
    countdownUntil: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      countdownSegments: {
        Hours: '00',
        Minutes: '00',
        Seconds: '00',
      },
      intervalId: null,
      isUpdated: { Hours: false, Minutes: false, Seconds: false },
      countdownReady: false,
      countdownEnded: false,
    }
  },
  computed: {
    isCountdownReadyToStart() {
      return (
        this.countdownReady &&
        !this.countdownEnded &&
        this.countdownSegments &&
        this.isUpdated
      )
    },
    validatedCountdownSegments() {
      return (
        this.countdownSegments || { Hours: '00', Minutes: '00', Seconds: '00' }
      )
    },
    validatedIsUpdated() {
      return this.isUpdated || { Hours: false, Minutes: false, Seconds: false }
    },
  },
  mounted() {
    this.startCountdown(this.countdownUntil)
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    startCountdown(endTime) {
      const end = new Date(endTime).getTime()
      if (isNaN(end)) {
        return
      }
      this.updateCountdown(end)
      this.countdownReady = true
      this.intervalId = setInterval(() => {
        this.updateCountdown(end)
      }, ONE_SECOND_IN_MILLISECONDS)
    },
    updateCountdown(end) {
      const now = new Date()
      const localOffset = now.getTimezoneOffset() * ONE_MINUTE_IN_MILLISECONDS
      const localTime = now.getTime() - localOffset

      const distanceInMilliseconds = end - localTime
      if (distanceInMilliseconds < 0) {
        clearInterval(this.intervalId)
        this.countdownEnded = true
        this.countdownSegments = {
          Hours: '00',
          Minutes: '00',
          Seconds: '00',
        }
      } else {
        const totalHours = Math.floor(
          distanceInMilliseconds / ONE_HOUR_IN_MILLISECONDS
        )
        const totalMinutes = Math.floor(
          (distanceInMilliseconds % ONE_HOUR_IN_MILLISECONDS) /
            ONE_MINUTE_IN_MILLISECONDS
        )
        const totalSeconds = Math.floor(
          (distanceInMilliseconds % ONE_MINUTE_IN_MILLISECONDS) /
            ONE_SECOND_IN_MILLISECONDS
        )

        this.updateSegment('Hours', totalHours)
        this.updateSegment('Minutes', totalMinutes)
        this.updateSegment('Seconds', totalSeconds)
      }
    },

    updateSegment(segment, value) {
      const formattedValue = String(value).padStart(2, '0')
      if (this.countdownSegments[segment] !== formattedValue) {
        this.isUpdated[segment] = true
        setTimeout(() => {
          this.isUpdated[segment] = false
        }, 300)
        this.$set(this.countdownSegments, segment, formattedValue)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$scree_small_phone: 399px;
$screen_large_phone: 599px;
$screen_medium_tablet: 769px;
$screen_large_tablet: 820px;
$screen_tablet_landscape: 853px;
$screen_small_laptop: 912px;
$screen_medium_desktop: 1280px;
$screen_large_desktop: 1700px;
$color-background: white;
$color-text: #333;
$color-separator: white;
$color-shadow: rgba(0, 0, 0, 0.1);
$color-shadow-hover: rgba(0, 0, 0, 0.2);
$color-text-shadow: rgba(0, 0, 0, 0.2);

.countdown {
  display: flex;
  gap: 8px;
  position: absolute;
  top: 57%;
  left: 300px;
  transform: translateY(-50%);
  padding: 8px 16px;
  box-shadow: 0px 4px 8px $color-shadow;
  border-radius: 12px;
  z-index: 10;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 6px 12px $color-shadow-hover;
  }
  @media (max-width: $screen_large_desktop) {
    left: 185px;
    padding: 6px 12px;
    gap: 6px;
  }
  @media (max-width: $screen_medium_desktop) {
    left: 160px;
  }
  @media (max-width: $screen_desktop) {
    left: 155px;
    padding: 6px 12px;
    gap: 6px;
  }
  @media (max-width: $screen_small_desktop) {
    top: 55%;
    left: 158px;
    padding: 5px 10px;
    gap: 5px;
  }
  @media (max-width: $v4_desktop) {
    left: 145px;
    padding: 4px 8px;
    gap: 4px;
  }
  @media (max-width: $screen_large) {
    top: 55%;
    left: 148px;
    padding: 3px 6px;
    gap: 3px;
  }
  @media (max-width: $screen_small_laptop) {
    left: 125px;
  }
  @media (max-width: $screen_tablet_landscape) {
    left: 105px;
  }
  @media (max-width: $screen_large_tablet) {
    top: 52%;
    left: 105px;
  }
  @media (max-width: $screen_tablet) {
    top: 12%;
    left: 70%;
    transform: translate(-50%, -50%);
    padding: 4px 6px;
    gap: 4px;
  }
  @media (max-width: $screen_medium) {
    padding: 4px 6px;
    gap: 4px;
  }
}
.countdown__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  background-color: $color-background;
  border-radius: 8px;
  transition: transform 0.3s ease;
  flex-shrink: 1;
  min-width: 100px;
  min-height: 60px;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: $screen_desktop) {
    min-width: 90px;
    min-height: 55px;
  }
  @media (max-width: $screen_small_desktop) {
    min-width: 80px;
    min-height: 50px;
  }
  @media (max-width: $v4_desktop) {
    min-width: 70px;
    min-height: 45px;
  }
  @media (max-width: $screen_large) {
    min-width: 65px;
    min-height: 40px;
  }
  @media (max-width: $screen_large_tablet) {
    min-width: 60px;
    min-height: 25px;
  }
  @media (max-width: $screen_medium_tablet) {
    min-width: 60px;
    min-height: 25px;
  }
  @media (max-width: $screen_tablet) {
    min-width: 100px;
    min-height: 80px;
  }
  @media (max-width: $screen_medium) {
    min-width: 85px;
    min-height: 60px;
  }
  @media (max-width: $screen_large_phone) {
    min-width: 60px;
    min-height: 60px;
  }
  @media (max-width: $scree_small_phone) {
    min-width: 50px;
    min-height: 55px;
  }
}
.countdown__value {
  font-weight: 700;
  color: $black_friday_color_primary;
  text-shadow: 1px 1px 2px $color-text-shadow;
  text-align: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
  &--updated {
    transform: scale(1.1);
    opacity: 0;
    animation: fadeIn 0.3s forwards;
  }
  @media (max-width: $screen_large_phone) {
    font-size: 22px;
  }
  @media (min-width: $screen_medium) {
    font-size: 40px;
  }
  @media (min-width: $screen_tablet) {
    font-size: 40px;
  }
  @media (min-width: $screen_large_tablet) {
    font-size: 18px;
  }
  @media (min-width: $screen_medium_tablet) {
    font-size: 18px;
  }
  @media (min-width: $screen_large) {
    font-size: 18px;
  }
  @media (min-width: $v4_desktop) {
    font-size: 20px;
  }
  @media (min-width: $screen_small_desktop) {
    font-size: 24px;
  }
  @media (min-width: $screen_desktop) {
    font-size: 28px;
  }
}
.countdown__label {
  color: $color-text;
  letter-spacing: 0.5px;
  text-align: center;
  @media (max-width: $screen_large_phone) {
    font-size: 7px;
  }
  @media (min-width: $screen_medium) {
    font-size: 12px;
  }
  @media (min-width: $screen_tablet) {
    font-size: 20px;
  }
  @media (min-width: $screen_large_tablet) {
    font-size: 8px;
  }
  @media (min-width: $screen_medium_tablet) {
    font-size: 8px;
  }
  @media (min-width: $screen_large) {
    font-size: 8px;
  }
  @media (min-width: $v4_desktop) {
    font-size: 9px;
  }
  @media (min-width: $screen_small_desktop) {
    font-size: 10px;
  }
}
.countdown__separator {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: $color-separator;
}
</style>
