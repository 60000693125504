/**
 * Check if given href redirecto to internal or external page
 * @param {String} href string value
 *
 * @returns {Boolean} true if href is external
 */
export default function isExternalLink(href) {
  const EXTERNAL_PREFIXES = ['http://', 'https://', 'www.', '.com.br', '.com']
  return !!EXTERNAL_PREFIXES.find((prefix) => href.match(prefix))
}
