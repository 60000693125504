export default {
  computed: {
    deviceType() {
      return this.$device.isMobile ? 'mobile' : 'desktop'
    },
  },
  methods: {
    getBannerImg(banner) {
      return this.deviceType === 'mobile'
        ? banner.mobile_image
        : banner.desktop_image
    },
  },
}
